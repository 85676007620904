import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-3003 Downloads",
  "path": "/Downloads/Indoor_Cameras/IN-3003/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Firmware, WebUI and Documentation Downloads",
  "image": "../D_SearchThumb_Indoor_Cameras.png",
  "social": "/images/Search/D_SearchThumb_Indoor_Cameras.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Download Area - IN-3003' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/P_SearchThumb_IN-3011_Downloads.png' twitter='/images/Search/P_SearchThumb_IN-3011_Downloads.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Innenkameras/IN-3003/' locationFR='/fr/Downloads/Indoor_Cameras/IN-3003/' crumbLabel="IN-3003" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "indoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#indoor-cameras",
        "aria-label": "indoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Indoor Cameras`}</h2>
    <h2 {...{
      "id": "in-3003-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-3003-downloads",
        "aria-label": "in 3003 downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-3003 Downloads`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software-installation-wizard"
        }}>{`Software Installation Wizard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instructions"
        }}>{`Instructions`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#quick-installation"
            }}>{`Quick Installation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#operation-manual"
        }}>{`Operation Manual`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-software-updates"
        }}>{`Camera software updates`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#firmware"
            }}>{`Firmware`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#download"
                }}>{`Download`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#web-interface"
            }}>{`Web Interface`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#download-1"
                }}>{`Download`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "software-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-installation-wizard",
        "aria-label": "software installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Installation Wizard`}</h2>
    <p>{`Every INSTAR product comes with an installation CD. The CD contains an installation assistant that guides you through the installation of your new INSTAR product. The installation wizard can also be called directly by clicking on the link below and opening the CD online. The wizard contains all the information and current download links you need for the installation.`}</p>
    <p>{`The software CD includes an HTML setup wizard that guides you through the required settings and displays the current download links for your INSTAR product.`}</p>
    <p><strong parentName="p">{`Quick Installation Wizard`}</strong>{`: The wizard guides you through the most important steps to get your INSTAR product up and running.`}</p>
    <p><strong parentName="p">{`Software Installation`}</strong>{`: You will find download links if your INSTAR product requires additional software. You are guided through all steps after you have completed the download and the installation.`}</p>
    <p><strong parentName="p">{`Firmware Updates`}</strong>{`: INSTAR provides firmware and WebUI updates for its products at regular intervals. Below you will find the latest versions of the firmware and WebUI which you can upload to your INSTAR camera.`}</p>
    <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">Start Installation</EuiButton></OutboundLink>
    <h2 {...{
      "id": "instructions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instructions",
        "aria-label": "instructions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructions`}</h2>
    <h3 {...{
      "id": "quick-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quick-installation",
        "aria-label": "quick installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Installation`}</h3>
    <p>{`The flyer contains the most important information about your INSTAR camera.`}</p>
    <Link to="https://wiki.instar.com/dl/IN-3003/flyer_3003.pdf" target="_blank" rel="noopener noreferrer" mdxType="Link"><EuiButton fill mdxType="EuiButton">Quick Start Guide</EuiButton></Link>
    <h2 {...{
      "id": "operation-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#operation-manual",
        "aria-label": "operation manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Operation Manual`}</h2>
    <p>{`The user manual contains detailed information about all camera functions.`}</p>
    <p><strong parentName="p">{`Quick Installation`}</strong>{`: The Quick Installation section provides an overview of all necessary steps to install your camera via LAN cable, WLan and over the Internet.`}</p>
    <p><strong parentName="p">{`The web interface`}</strong>{`: The web interface area gives you an overview of all the functions of the camera and how to configure it when you access the camera with the web browser.`}</p>
    <p><strong parentName="p">{`Technical specifications`}</strong>{`: You will find a list of all technical specifications of your IP camera.`}</p>
    <p><strong parentName="p">{`Product features and safety warnings`}</strong></p>
    <p><strong parentName="p">{`Windows software, smartphone apps and video streaming`}</strong></p>
    <p><strong parentName="p">{`Camera reset, lens adjustment, warranty and disposal`}</strong></p>
    <p><strong parentName="p">{`FAQ questions and answers`}</strong></p>
    <Link to="https://wiki.instar.com/dl/IN-3003/QuickInstallation_IN-3003_web_de.pdf" target="_blank" rel="noopener noreferrer" mdxType="Link"><EuiButton fill mdxType="EuiButton">Anleitung <small>Deutsch</small></EuiButton></Link>
    <Link to="https://wiki.instar.com/dl/IN-3001/QuickInstallation-VGA-IN-3001-EN.pdf" target="_blank" rel="noopener noreferrer" mdxType="Link"><EuiButton fill mdxType="EuiButton">Manual <small>English</small></EuiButton></Link>
    <h2 {...{
      "id": "camera-software-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-software-updates",
        "aria-label": "camera software updates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera software updates`}</h2>
    <h3 {...{
      "id": "firmware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#firmware",
        "aria-label": "firmware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Firmware`}</h3>
    <p>{`You will always find here the latest version and all previous versions of the firmware for your IN-3001. If you plan to update the WebUI, make sure that you have installed the latest version of the firmware. In most cases, the new features in the web interface (WebUI) also require the latest firmware. For information on how to update the firmware please look `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/WebUI_And_Firmware_Upgrade/"
      }}>{`Here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Important`}</strong>{`: What firmware version do you need? Please check the second number group for the version number. If your current firmware currently has a `}<strong parentName="p">{`37`}</strong>{` or `}<strong parentName="p">{`35`}</strong>{` second, then please select the firmware 3 `}<em parentName="p">{` second. Otherwise, if the number is `}<strong parentName="em">{`22`}</strong>{`, `}<strong parentName="em">{`25`}</strong>{` or a different number, please select the firmware with the 2 `}</em>{` second.`}</p>
    <p><strong parentName="p">{`Important`}</strong>{`: Unzip the * .ZIP file after downloading. Please note that you must first install the latest firmware and then the WebUI.`}</p>
    <h4 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-3003/Firmware_57_35_2_59.bin"
        }}>{`57.37.2.59`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Integration of the Wlan country selection`}</li>
          <li parentName="ul">{`Audio alarm with volume control`}</li>
          <li parentName="ul">{`No-IP integrates as a third-party DDNS provider`}</li>
          <li parentName="ul">{`Advanced motion detection`}</li>
          <li parentName="ul">{`FTP connection maintained`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "web-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#web-interface",
        "aria-label": "web interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web Interface`}</h3>
    <p>{`You will always find the latest version and all previous versions of the web interface for your INSTAR Camera. Just download the latest version below and play the update with your INSTAR Camera Tool on your camera (see below). Please also ensure that your camera has the latest firmware installed. If you do not know how to install the latest version of the WebUI on your camera, please look `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/WebUI_And_Firmware_Upgrade/"
      }}>{`Here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Important`}</strong>{`: Unzip the * .ZIP file after downloading. Also, install the latest firmware before you install the new WebUI.`}</p>
    <h4 {...{
      "id": "download-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download-1",
        "aria-label": "download 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-3003/WebUI_IN-3003_3_17_0_61.zip"
        }}>{`30.03.3.17.0`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Add the cameras with QR code`}</li>
          <li parentName="ul">{`Push-Service`}</li>
          <li parentName="ul">{`Smaller bugs and typing errors`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-3001/WebUI_30_01_3_16_2_51.zip"
        }}>{`30.01.3.16.2`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Support for Microsoft Internet Explorer 11`}</li>
          <li parentName="ul">{`Faster loading times for Internet access`}</li>
          <li parentName="ul">{`Separate schedule for FTP upload`}</li>
          <li parentName="ul">{`Display the MAC address of the WLAN module`}</li>
          <li parentName="ul">{`Password check for FTP, email and WLAN`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      